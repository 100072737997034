function init_vacatures() {
    if(typeof count_vacatures !== 'undefined' && count_vacatures != null) {
        var $target_el = $('.js-count-vacatures');
        $target_el = $target_el.find('h2');

        // Target exists
        if($target_el.length) {
            var count_class = '';
            if(count_vacatures == 0) {
                count_class = 'd-none';
            }
            $target_el.after('<span class="dynamic-vacatures-count '+count_class+'">'+count_vacatures+'</span>');
        }
        else {
            console.warn('Vacature count variable found, but no target found');
        }
    }
    else {
        console.log('no count');
    }
}

$(document).ready(function() {
    init_vacatures();
});