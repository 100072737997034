function init_banner() {
    var $banner = $('.js-banner');

    // Exists
    if(!$banner.length) {
        return false;
    }

    var $slider_wrapper = $banner.find('.banner__slider');
    var $slides = $slider_wrapper.children();

    // Slides exist
    if(!$slides.length) {
        return false;
    }

    $slider_wrapper.slick({
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        waitForAnimate: false,
        dots: false,
        pauseOnFocus: false,
        pauseOnHover: false
    });

    // Setup keywords
    var $keyword_slides = $slider_wrapper.find('[data-sleutelwoord]');
    var $keyword_div = $banner.find('.banner__keywords');

    $keyword_slides.each(function() {
        $keyword_slide = $(this);
        keyword_val = $(this).attr('data-sleutelwoord');

        if(keyword_val) {
            $keyword_div.append('<button style="opacity:0;" class="js-banner-keyword has-chevron-right-white" data-sleutelwoord-target="'+keyword_val+'">'+keyword_val+'</button>');
        }
    });

    // Fade in the keywords
    $keyword_div.children().each(function (i) {
        var element = $(this);
        setTimeout(function() {
          element.animate({"opacity": 1}, 500);
        }, 400*i);
    });

    // Setup the image animation
    $slider_wrapper.find('.slick-current').addClass('is-zoom-animation-active'); // first slide

    // Fist keywords
    var current_slide_index = $slider_wrapper.slick('slickCurrentSlide');
    var $keywords = $keyword_div.children();
    $keywords.removeClass('current-keyword');
    $keywords.eq(current_slide_index).addClass('current-keyword');

    // Continue setting up the events for animation and keywords
    $slider_wrapper.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $slider_wrapper.find(".slick-slide").removeClass('is-zoom-animation-active');
    });

    $slider_wrapper.on('afterChange', function(event, slick, currentSlide, nextSlide) {
        var $current_afterslide = $slider_wrapper.find('.slick-current');
        $current_afterslide.addClass('is-zoom-animation-active');

        // and change the keywords color
        var current_slide_index = $slider_wrapper.slick('slickCurrentSlide');
        $keywords.removeClass('current-keyword');
        $keywords.eq(current_slide_index).addClass('current-keyword');
    });

    $('.js-banner-keyword').on('click', function() {
        target_keyword = $(this).attr('data-sleutelwoord-target');
        if(target_keyword) {
            var $target_slide = $slider_wrapper.find('[data-sleutelwoord="'+target_keyword+'"]');
            var target_slide_slick_index = $target_slide.attr('data-slick-index');

            $slider_wrapper.slick('slickGoTo', target_slide_slick_index);
        }
    });

}

$(document).ready(function() {
    init_banner();
});