function init_blokken() {
    var $blokken_secties = $('.blokken-sectie');

    if($blokken_secties && $blokken_secties.length) {
        $blokken_secties.each(function() {
            $single_sectie = $(this);
            $blokken = $single_sectie.find('.blokken-sectie__content');

            if($blokken && $blokken.length) {
                $blokken.children().on('mouseenter', function() {
                    set_blokken_image($(this), $single_sectie);
                });
            }
        });
    }

    function set_blokken_image($blok, $sectie) {
        // Don't do anything for mobile users
        if($(window).width() < 768) {
            return false;
        }

        if($blok && $sectie && $blok.length && $sectie.length) {
            var img_src = $blok.attr('data-hover-image');

            if(img_src && img_src.length > 1) {
                var $img = $sectie.find('.blokken-sectie__backdrop__img > img');
                var fade_speed = 500;
                // Hide
                $img.addClass('sectie-image-hidden');

                setTimeout(function() {
                    // Remove src to properly catch load event
                    $img.removeAttr('src');

                    // Add handler to check if image is loaded
                    $img.on('load', function() {
                        $img.removeClass('sectie-image-hidden');
                        // Reset handler
                        $img.off('load');
                    });

                    $img.attr('src', img_src);
                }, fade_speed);
            }
        }
    }
}

$(document).ready(function() {
    init_blokken();
});