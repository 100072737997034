function init_faq() {
    var $render_popup_triggers = $('.js-render-faq-popup');

    if($render_popup_triggers && $render_popup_triggers.length) {
        $render_popup_triggers.on('click', function() {
            var $clicked_trigger = $(this);
            var title = '';
            var content = '';

            clicked_title = $clicked_trigger.attr('data-faq-title');
            if(clicked_title) {
                title = clicked_title;
            }
            clicked_content = $clicked_trigger.attr('data-faq-content');
            if(clicked_content) {
                content = clicked_content;
            }

            if(title && content) {
                render_faq_popup(title, content);
            }
            else {
                console.error('#3534312 Tried rendering FAQ item, but missing title or content.');
            }
        });
    }

    function render_faq_popup(title, content) {
        var $popup = $('.faq-popup');
        var $window = $popup.find('.faq-popup__window');
        var $backdrop = $popup.find('.faq-popup__backdrop');
        var $close_button = $popup.find('.faq-popup__close');
        var $title = $popup.find('.faq-popup__title');
        var $content = $popup.find('.faq-popup__content');

        // Insert the content
        $title.html(title);
        $content.html(content);

        // Show the parent element, containing the backdrop
        $popup.show();
        $popup.animate({ opacity: 1 });

        // CSS animate the window
        $popup.addClass('is-active');

        // Enable closing the popup
        $backdrop.on('click', function() {
            close_faq_popup();
        });

        $close_button.on('click', function() {
            close_faq_popup();
        });

        // Handlers
        document.onkeydown = function(event) {
            // Close finder
            if (event.keyCode == 27) {
                event.preventDefault();
                close_faq_popup();
            }
        };
    }

    function close_faq_popup() {
        var $popup = $('.faq-popup');
        var $backdrop = $popup.find('.faq-popup__backdrop');
        var $close_button = $popup.find('.faq-popup__close');
        var $title = $popup.find('.faq-popup__title');
        var $content = $popup.find('.faq-popup__content');

        $backdrop.off('click');
        $close_button.off('click');
        $popup.animate({ opacity: 0 });
        $popup.removeClass('is-active');

        // Await animation
        setTimeout(function() {
            $popup.hide();
            $title.html('');
            $content.html('');
        }, 400);
    }
}

$(document).ready(function() {
    init_faq();
});