// Start the filename with 1 to run first. Prevent potential breaks

function is_IE() {
    var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    var msie = ua.indexOf('MSIE '); // IE 10 or older
    var trident = ua.indexOf('Trident/'); //IE 11

    return (msie > 0 || trident > 0);
}

if(is_IE()) {
    ie_docReady(function() {
        document.body.classList.add("is-internet-explorer");

        if(ie_getCookie('ie_notice_closed') && ie_getCookie('ie_notice_closed') == 'true') {
            document.body.classList.add("ie-notice-closed");
            console.warn('Using order browser, but notice has been closed.');
        }

        // Notice has not been closed, show it
        else {
            document.body.classList.add("show-ie-notice");
            ie_setCookie('ie_notice_closed', 'false', 7);

            var close_button = document.getElementById('internet-explorer-notice-close');

            close_button.addEventListener("click", function(e){
                document.body.classList.remove("show-ie-notice");
                ie_setCookie('ie_notice_closed', 'true', 7);

                setTimeout(function() {
                    document.body.classList.add("ie-notice-closed");
                }, 600);
            },false);
        }
    });
}

function ie_docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

function ie_setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function ie_getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}